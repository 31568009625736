<template>
    <div class="presentation">
        <section class="section">
            <div class="container" @mouseover="hover = true" @mouseleave="hover = false">
                <hr>
                <h1 class="title has-text-centered" v-bind:class="[hover ? 'has-text-danger-dark' : '']">Presentation</h1>
                <hr>
                <h2 class="subtitle has-text-centered">
                    MB Expert Informatique
                </h2>
                
                <div class="block has-text-left">
                    <p class="mb-2">Auto-entrepreneur dans le domaine de l'informatique, c'est avec plaisir que je mets à disposition mes compétences et connaissances afin de répondre au mieux à vos demandes.</p>
                    <p class="mb-2">Fort d'une exprience personnelle et professionnelle dans divers secteurs du domaine informatique et ce depuis plusieurs années, nous pourrons trouver ensemble une solution sur mesure et adapter à vos besoins.</p>
                    <p class="mb-2">N'hésitez pas à me contacter afin qu'on puisse définir un plan d'action adapté à votre demande selon vos besoins.</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Presentation',
    data() {
        return {
            hover: false
        }
    }

}
</script>

<style>

</style>