<template>
    <div class="services">
        <section class="section">
            <div class="container" @mouseover="hover = true" @mouseleave="hover = false">
                <hr>
                <h1 class="title has-text-centered" v-bind:class="[hover ? 'has-text-danger-dark' : '']">Services</h1>
                <hr>
                <div class="columns is-centered">
                    <div class="column">
                        <div @mouseover="hoverAssist = true" @mouseleave="hoverAssist = false" class="box" v-bind:class="[hoverAssist ? 'has-background-white-ter' : 'has-background-primary-light']" style="height: 400px;">
                            <h2 class="title has-text-centered">
                                <span class="icon-text ">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-24px mdi-wrench"></i>
                                    </span>
                                    <span>Assistance</span>
                                </span>
                            </h2>
                            <hr class="has-background-danger-dark">
                            <ul class="has-text-centered">
                                <li class="mb-3">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-wrench"></i>
                                    </span>
                                    <span>Diagnostique PC, PC Portable, Smartphone, Tablette</span>
                                </li>
                                <li class="mb-3">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-wrench"></i>
                                    </span>
                                    <span>Réparation PC, PC Portable, Smartphone, Tablette</span>
                                </li>
                                <li class="mb-3">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-wrench"></i>
                                    </span>
                                    <span>Sauvegarde et récupération de donnée</span>
                                </li>
                                <li class="mb-3">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-wrench"></i>
                                    </span>
                                    <span>Installation et configuration système d'exploitation</span>
                                </li>
                            </ul>
                            <!--
                            <p class="test">
                                I'm in a box.
                            </p>
                            -->
                        </div>
                    </div>
                    <div class="column">
                        <div @mouseover="hoverDev = true" @mouseleave="hoverDev = false" class="box" v-bind:class="[hoverDev ? 'has-background-white-ter' : 'has-background-primary-light']" style="height: 400px;">
                            <h2 class="title has-text-centered">
                                <span class="icon-text ">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-24px mdi-code-tags"></i>
                                    </span>
                                    <span>Developpement</span>
                                </span>
                            </h2>
                            <hr class="has-background-danger-dark">
                            <ul class="has-text-centered">
                                <li class="mb-3">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-code-tags"></i>
                                    </span>
                                    <span>Automatisation de tâche</span>
                                </li>
                                <li class="mb-3">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-code-tags"></i>
                                    </span>
                                    <span>Application et site Web</span>
                                </li>
                                <li class="mb-3">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-code-tags"></i>
                                    </span>
                                    <span>Création de base de donnée</span>
                                </li>
                                <li class="mb-3">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-code-tags"></i>
                                    </span>
                                    <span>Création d'API</span>
                                </li>
                                <li class="mb-3">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-code-tags"></i>
                                    </span>
                                    <span>Mise à jour d'API</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="column">
                        <div @mouseover="hoverAccompagnement = true" @mouseleave="hoverAccompagnement = false" class="box" v-bind:class="[hoverAccompagnement ? 'has-background-white-ter' : 'has-background-primary-light']" style="height: 400px;">
                            <h2 class="title has-text-centered">                               
                                <span class="icon-text ">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-24px mdi-hexagon-multiple"></i>
                                    </span>
                                    <span>Accompagnement</span>
                                </span>
                            </h2>
                            <hr class="has-background-danger-dark">
                            <ul class="has-text-centered">
                                <li class="mb-3">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-hexagon-multiple"></i>
                                    </span>
                                    <span>Mise en place de projet sur mesure</span>
                                </li>
                                <li class="mb-3">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-hexagon-multiple"></i>
                                    </span>
                                    <span>Suivi et gestion de projet</span>
                                </li>
                                <li class="mb-3">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-hexagon-multiple"></i>
                                    </span>
                                    <span>Formation technique selon le sujet et le niveau exigé</span>
                                </li>
                                <li class="mb-3">
                                    <span class="icon has-text-danger-dark">
                                        <i class="mdi mdi-hexagon-multiple"></i>
                                    </span>
                                    <span>Conseil et avis technique</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Services',
    data() {
        return {
            hover: false,
            hoverAssist: false,
            hoverDev: false,
            hoverAccompagnement: false,
            isPointed: false,
            pointedClass: 'has-background-danger-light'
        }
    },
    methods: {
        test(event) {
            console.log(event)
            this.isPointed = true
        }
    }    

}
</script>

<style>

.test {
    color: #E29578;
}

.box:hover {
    background-color: #E29578;
}

</style>