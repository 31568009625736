<template>
  <div class="app">
    <menu-app></menu-app>
    <router-view class="mt-5"/>
    <footer class="footer has-background-primary-light">
      <div class="container has-text-centered">
        <div class="columns is-mobile is-centered is-vcentered">
          <div class="column">
            <img src="./assets/mb_logo.png" alt="">
          </div>
          <div class="column">
            <span class="Subtitle"><strong>© 2022 - MB Expert Informatique</strong></span><br>
            <span><strong>Bulma</strong> and <strong>VueJS</strong> for Website.</span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Menu from './components/Menu/Menu.vue'

export default {
  name: 'App',
  components: {
    'menu-app': Menu,
  },
}
</script>

<style>

</style>
