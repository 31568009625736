<template>
  <div class="menu-app">
    <div class="nav-menu">
    <nav class="navbar is-light is-fixed-top has-background-primary-light" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" v-on:click="$router.push('/')">
          <img src="../../assets/mb_logo.png">
        </a>

        <a role="button" class="navbar-burger" @click="showNav = !showNav" :class="{ 'is-active': showNav }" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': showNav }">
        <div class="navbar-start">
          <a class="navbar-item" v-on:click="scrollToTop">
            Accueil
          </a>

          <a class="navbar-item" href="#presentation">
            Presentation
          </a>

          <a class="navbar-item" href="#services">
            Services
          </a>

          <!--
          <a class="navbar-item" v-on:click="$router.push('/contact')">
            Contact
          </a>
          -->
          
          <a class="navbar-item" href="#contact">
            Contact
          </a>
          
        </div>
      </div>
    </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {
      showNav: false
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar-start{
    justify-content: center;
    text-align: center;
}

.navbar-item img {
    max-height: 4rem;
}
</style>
