<template>
    <div class="home">
        <presentation id="presentation"></presentation>
        <services id="services"></services>
        <contact id="contact"></contact>
    </div>
</template>

<script>
import Presentation from '../Presentation/Presentation.vue'
import Services from '../Services/Services.vue'
import Contact from '../Contact/Contact.vue'

export default {
    name: 'Home',
    components: {
    'presentation': Presentation,
    'services': Services,
    'contact': Contact
  }
    

}
</script>

<style>

</style>