<template>
    <div class="contact">
        <section class="section">
            <div class="container" v-if="!sendingMail" @mouseover="hover = true" @mouseleave="hover = false">
                <hr>
                <h1 class="title has-text-centered" v-bind:class="[hover ? 'has-text-danger-dark' : '']">Contact</h1>
                <hr>
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">De</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <p class="control is-expanded has-icons-left">
                                <input class="input" type="text" placeholder="Nom*" v-model="watchName" v-bind:class="[formData.name ? 'is-success' : '']">
                                <span class="icon is-small is-left">
                                <i class="mdi mdi-24px mdi-account"></i>
                                </span>
                            </p>
                        </div>
                        <div class="field">
                            <p class="control is-expanded has-icons-left">
                                <input class="input" type="email" placeholder="Email*" v-model="watchMail" v-bind:class="[formData.mail ? 'is-success' : '']">
                                <span class="icon is-small is-left">
                                <i class="mdi mdi-24px mdi-email"></i>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label"></div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field has-addons">
                                <p class="control">
                                    <a class="button is-static">
                                        FR +33
                                    </a>
                                </p>
                                <p class="control is-expanded">
                                    <input class="input" type="tel" placeholder="Numéro de téléphone" v-model="watchPhone" v-bind:class="[formData.phone ? 'is-success' : '']">
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Service</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-narrow">
                            <div class="control">
                                <div class="select is-fullwidth" v-bind:class="[formData.service ? 'is-success' : '']">
                                    <select v-model="formData.service">
                                        <option>Assistance</option>
                                        <option>Developpement</option>
                                        <option>Accompagnement</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Sujet</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <input class="input" type="text" placeholder="Création d'un site web*" v-model="watchSubject" v-bind:class="[formData.subject ? 'is-success' : '']">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Message</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <textarea class="textarea" placeholder="Bonjour, pouvons nous convenir d'un rendez-vous...*" v-model="watchMessage" v-bind:class="[formData.message ? 'is-success' : '']"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label">
                            <!-- Left empty for spacing -->
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <button class="button" type="submit" v-bind:class="[checkFormData ? 'is-success is-outlined' : 'is-danger']" :disabled="isDisabledButton" v-on:click="sendMail()">
                                Envoyer
                                </button>
                                <p class="help is-danger" v-if="isDisabledButton">
                                    Completer les champs
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" v-else>
                <hr>
                <progress class="progress is-medium is-primary" max="100" v-if="isSendingMail">15%</progress>
                <article class="message" v-bind:class="[sendingMailSuccess ? 'is-success' : 'is-danger']" v-else>
                    <div class="message-header">
                        <p>Information Email</p>
                        <button class="delete" aria-label="delete" v-on:click="resetMail()" v-if="sendingMailSuccess"></button>
                        <button class="delete" aria-label="delete" v-on:click="sendingMail = false" v-else></button>
                    </div>
                    <div class="message-body">
                        <strong>{{mailResponse}}</strong>
                    </div>
                </article>
            </div>
        </section>
    </div>
</template>

<script>
import emailjs from '@emailjs/browser';
export default {
    name: 'Contact',
    data() {
        return {
            hover: false,
            watchName: '',
            watchMail: '',
            watchPhone: '',
            //watchService: 'Assistance',
            watchSubject: '',
            watchMessage: '',
            isValidMail: false,
            formData: {
                name: '',
                mail: '',
                phone: '',
                service: 'Assistance',
                subject: '',
                message: ''
            },
            checkFormData: false,
            isDisabledButton: true,
            isSendingMail: false,
            sendingMail: false,
            sendingMailSuccess: false,
            mailResponse: ''
            
        }
    },
    methods: {
        showForm() {
            console.log(this.formData)
            console.log(this.isSendingMail)
            this.isSendingMail = true
            console.log(this.isSendingMail)
        },
        formDataChecked() {
            if (this.formData.name != "" && this.isValidMail && this.formData.service != "" && this.formData.subject != "" && this.formData.message != "") {
                this.checkFormData = true
                this.isDisabledButton = false
                console.log(this.formData)
            }
            else {
                console.log("Il manque des champs")
                this.checkFormData = false
                this.isDisabledButton = true
            }
        },
        sendMail() {
            console.log(this.formData)
            console.log(this.isSendingMail)
            this.sendingMail = true
            this.isSendingMail = true
            console.log(this.isSendingMail)
            emailjs.send('service_46hmx79', 'template_v7fkjfb', this.formData, 'ARFjln6FkwdlsSVpV')
            .then((result) => {
                console.log('SUCCESS!', result.text);
                this.isSendingMail = false
                this.sendingMailSuccess = true
                this.mailResponse = "Succès! Un mail vient d'être envoyé à mb.expert.informatique@gmail.com."
            }, (error) => {
                console.log('FAILED...', error.text);
                this.mailResponse = "Erreur! Merci de réessayer ultérieurement."
            });
        },
        resetMail() {
            this.watchName = ''
            this.watchMail = ''
            this.watchPhone = ''
            this.watchSubject = ''
            this.watchMessage = ''
            for (var elt in this.formData) {
                this.formData[elt] = ''
            }
            this.checkFormData = false
            this.isDisabledButton = true
            this.isSendingMail = false
            this.sendingMail = false
            this.sendingMailSuccess = false
            this.mailResponse = ''
            this.isValidMail = false
        },
        validateEmail(val) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(val)) {
                this.isValidMail = true
                this.formData.mail = val
                this.formDataChecked()
            } else {
                this.isValidMail = false
                this.formData.mail = ''
                this.formDataChecked()
            }
        }
    },
    watch: {
        watchName(val) {
            this.formData.name = val
            this.formDataChecked()
        },
        watchMail(val) {
            this.validateEmail(val)
            //this.formDataChecked()
        },
        watchPhone(val) {
            this.formData.phone = val
            this.formDataChecked()
        },
        //watchService(val) {
        //    this.formData.service = val
        //    this.formDataChecked()
        //},
        watchSubject(val) {
            this.formData.subject = val
            this.formDataChecked()
        },
        watchMessage(val) {
            this.formData.message = val
            this.formDataChecked()
        }
    }

}
</script>

<style>

</style>